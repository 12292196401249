import React from "react";

import Footer from "../layout/footer/Footer";
import Navbar from "../layout/header/Navbar";
import Layout from "../layout/Layout";
import PageHeader from "../team/PageHeader";
import HeroSix2 from "../../themes/index-6/HeroSix2";
import image3 from "../../img/swag-image.png"




export default function Article5() {

  
  return (
    <Layout>
            <Navbar darkBg/>
      <HeroSix2 />
    
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                Read the First Project Newsletter - All Our Research and Developments in One Place!

                          </h3>
                          <br/>
                          <p>Our first project Newsletter is now out. It features our latest research, developments and news, as well as an overview of the project and partners. You can read it 
                          <a style={{color:'#f45652'}} href='https://mailchi.mp/95b123e55adf/swag-newsletter-1-discover-our-latest-developments-achievements'> here</a>. </p>

                <p>Stay updated on our groundbreaking work in soft robotics and our lower limb exosuits - sign up to receive the next Newsletter directly <a style={{color:'#f45652'}} href='https://t.co/KfnI1575UI'>here</a>. We publish two editions a year. </p>
 
 </div>      </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
