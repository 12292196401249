import React from "react";

import Footer from "../layout/footer/Footer";
import Navbar from "../layout/header/Navbar";
import Layout from "../layout/Layout";
import PageHeader from "../team/PageHeader";
import HeroSix2 from "../../themes/index-6/HeroSix2";
import image3 from "../../img/Picture112.png"




export default function Article4() {

  
  return (
    <Layout>
            <Navbar darkBg/>
      <HeroSix2 />
    
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#e95d04'}}>
                New research promises improved design and interfacing of exosuits

                          </h3>
                          <br/>
                          <p><a style={{color:'#f45652'}} href='https://www.linkedin.com/in/orla-rispens-6771aa1b7/?originalSubdomain=nl'>Orla Rispens</a> has developed novel deep learning methods to improve the wearability and speed of measuring muscle activity as part of her master thesis at the Neuromuscular Robotics Chair at SWAG partner <a href='https://www.utwente.nl/en/' style={{color:'#f45652'}}>University of Twente</a>. This has potential implications for improving design and interfacing of wearable assistive robots. </p>
 <p>Muscle activity detection using surface electromyography (sEMG) typically requires precise and time-consuming electrode placement. Dimensionality reduction methods have been commonly used to automatically locate the muscle activity of interest from a grid of sensors. However, replicating this for different muscle groups is non-trivial. Therefore, Orla developed new techniques using two deep learning methods: convolutional neural networks (CNN) and encoder-decoder networks (EDN). Additionally, she built a garment embedded with a grid of sensors to collect data from the legs. </p>
 <img
                  src={image3}
                  style={{borderRadius:'10px',width:'130px',float:'right',marginLeft:'20px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <p>Data was collected from participants performing different types of walking, and on separate days to validate the new methods. CNN and EDN performed significantly better than existing techniques, with high correlations of 90% with reference measurements for locating the muscle activity of interest from the lower leg. This was validated on 8 participants. A similar result was found for the upper leg, although only 3 participants were measured. </p>
                <p>These new approaches could result in the design of smart garments that automatically locate muscle activities of interest to monitor musculoskeletal states of the user. This could in turn provide information about the musculoskeletal health or help inform wearable assistive robots to seamlessly interface with the user. </p>
 <p>The findings discussed in the Master’s thesis will feed into the development of the SWAG solution, which seeks to revolutionise human motion assistance through fabric-based soft robotics, integrated sensing, and AI-driven user intent tracking.  </p>
 <p>You can read the thesis <a style={{color:'#f45652'}} href='https://essay.utwente.nl/102809/1/Rispens_MA_ET.pdf'>here</a>.</p>
 
 </div>      </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
